header {
  height: 100vh;
  padding-top: 4rem;
}

/* this is to control the height of the header gap btw about me page */
.header {
  height: 100vh;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
/*Let's talk btn */
#cta-talk {
  color: white;
}
#cta-talk:hover {
  color: black;
}

/* =========================Social Media ===================*/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 10rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ========================= Profile ===================*/

.me {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4rem;
  overflow: hidden;
  padding: 0 1.5rem;
}

/* ========================= Scroll down ===================*/
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 11rem;
}

/* for tablet */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
  .header {
    height: 65vh;
  }
}

/* for phone */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
  .header {
    height: 58vh;
  }
}
