nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.2rem 1rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 1rem;
  backdrop-filter: blur(15px);
  justify-content: center;
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 10%;
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  text-align: center;
  gap: 0.4rem;
  color: white;
}

nav a:hover {
  background: #2771ff;
  border-radius: 8%;
  margin: 10px 0px;
}

nav a.active {
  background: #2771ff;
  color: white;
  border-radius: 12%;
  margin: 10px 0px;
}

@media screen and (max-width: 1024px) {
}

/* for phone */
@media screen and (max-width: 600px) {
  nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.2rem 0.5rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3rem;
    display: flex;
    gap: 0.5rem;
    border-radius: 1rem;
    backdrop-filter: blur(15px);
    justify-content: center;
  }
  nav a {
    background: transparent;
    padding: 0.05rem;
    border-radius: 10%;
    display: flex;
    font-size: 0.7rem;
    align-items: center;
    text-align: center;
    gap: 0.2rem;
    color: white;
  }
}
