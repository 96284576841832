.logo {
  width: 3%;
  height: 3%;
  display: inline-block;
  margin: 5px 0 35px 0;
}

footer {
  background: var(--color-bg-variant);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 8rem;
}

footer a {
  color: var(--color-white);
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
  font-size: 20px;
}
.copyright {
  color: #81acff;
}
@media screen and (max-width: 600px) {
  .logo {
    width: 8%;
    height: 5%;
  }

  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }
}
